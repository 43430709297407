function App() {
  return (
    <div
      style={{
        minHeight: "90vh",
        display: "flex",
      }}
    >
      <div
        style={{
          flexShrink: 0,
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          width: 380,
        }}
      >
        <iframe
          scrolling="no"
          title="mw.elemti.com"
          src="https://mw.elemti.com/"
          frameBorder={0}
          style={{
            zIndex: 1,
            alignSelf: "flex-end",
            height: 50,
            width: 120,
            marginBottom: -30,
            overflow: "hidden",
          }}
        ></iframe>
        <img src="/birb.png" alt="birb" style={{ width: 294 }} />
      </div>
    </div>
  );
}

export default App;
